frappe.provide('frappe.nxlite.utils.estoque');

// tipo = Entrada || Saída
// tipo_movimento = Recebimento | Produção | Inventário | Cancelamento || Saída | Saída Automática | Inventário | Cancelamento
const estoque_utils = {
    /**
     * Movimenta o estoque com base nos parâmetros fornecidos.
     *
     * @param {Object|Object[]} data - Os dados necessários para movimentar o estoque.
     * @param {string} data.empresa - O name do Doctype de empresa referente.
     * @param {string} data.tipo - O tipo de movimento, pode ser 'Entrada' ou 'Saída'.
     * @param {string} data.tipo_movimento - O tipo de movimento específico:
     *        'Entrada': 'Recebimento', 'Produção', 'Inventário' ou 'Cancelamento'.
     *        'Saída': 'Saída', 'Saída Automática', 'Inventário' ou 'Cancelamento'.
     * @param {string} data.doc_origem - O nome do Doctype de origem.
     * @param {string} data.id_doc_origem - O ID do documento de origem.
     * @param {string} [data.movimento_gerador] - O name do Doctype de Movimento de Estoque, obrigatório se tipo_movimento for 'Saída Automática'.
     * @param {Array.<Object>} data.itens - Os itens a serem movimentados.
     * @param {string} data.itens[].item - O name do Doctype de Item.
     * @param {number} data.itens[].qtde - A quantidade a ser movimentada.
     * @param {number} data.itens[].valor - O custo unitário do item.
     */
    movimenta_estoque: async (data) => {
        if (!Array.isArray(data)) {
            data = [data];
        }
        
        // Loop de verificações
        for (const obj of data) {
            await estoque_utils.validate_params_movimenta_estoque(obj);
        }

        // Loop de operações
        const created = [];
        for (const obj of data) {
            const {
                empresa,
                tipo,
                tipo_movimento,
                itens,
                id_doc_origem,
                doc_origem,
                movimento_gerador,
            } = obj;

            const res = await frappe.call(
                'nxlite.nx_estoque.doctype.movimento_de_estoque.movimento_de_estoque.movimenta_estoque',
                {
                    empresa,
                    tipo,
                    tipo_movimento,
                    itens,
                    id_doc_origem,
                    doc_origem,
                    movimento_gerador,
                },
            );

            created.push(...res.message);
        }
        return created;
    },

    validate_params_movimenta_estoque: async (params) => {
        if (!params || typeof params !== 'object') {
            throw new Error(
                'Os parâmetros devem ser fornecidos como um objeto.',
            );
        }

        const {
            empresa,
            tipo,
            tipo_movimento,
            itens,
            id_doc_origem,
            doc_origem,
            movimento_gerador,
        } = params;

        if (!empresa || typeof empresa !== 'string') {
            throw new Error(
                'A empresa relacionada à movimentação de estoque é uma informação obrigatória e deve ser uma string.',
            );
        }

        const tipos = ['Entrada', 'Saída'];
        if (!tipos.includes(tipo)) {
            throw new Error(
                'Os tipos de movimentação de estoque válidos são: "Entrada" ou "Saída".',
            );
        }

        const tipos_movimento_entrada = [
            'Recebimento',
            'Produção',
            'Inventário',
            'Cancelamento',
        ];
        const tipos_movimento_saida = [
            'Saída',
            'Saída Automática',
            'Inventário',
            'Cancelamento',
        ];

        if (
            tipo === 'Entrada' &&
            !tipos_movimento_entrada.includes(tipo_movimento)
        ) {
            throw new Error(
                'Os tipos de entrada de estoque válidos são: "Recebimento", "Produção", "Inventário" ou "Cancelamento".',
            );
        }

        if (
            tipo === 'Saída' &&
            !tipos_movimento_saida.includes(tipo_movimento)
        ) {
            throw new Error(
                'Os tipos de saída de estoque válidos são: "Saída", "Saída Automática", "Inventário" ou "Cancelamento".',
            );
        }

        if (!Array.isArray(itens) || itens.length === 0) {
            throw new Error(
                'É necessário ao menos um item para gerar movimentação de estoque, e "itens" deve ser um array.',
            );
        }

        for (const [index, item] of itens.entries()) {
            if (typeof item !== 'object' || !item) {
                throw new Error(
                    `Cada item deve ser um objeto. Erro no item na posição ${index}.`,
                );
            }

            const { item: itemName, qtde, valor } = item;

            if (!itemName || typeof itemName !== 'string') {
                throw new Error(
                    `A propriedade "item" de cada item deve ser uma string válida. Erro no item na posição ${index}.`,
                );
            }

            if (typeof qtde !== 'number' || qtde < 0) {
                throw new Error(
                    `A propriedade "qtde" de cada item deve ser um número positivo. Erro no item na posição ${index}.`,
                );
            }

            if (typeof valor !== 'number' || valor < 0) {
                throw new Error(
                    `A propriedade "valor" de cada item deve ser um número não negativo. Erro no item na posição ${index}.`,
                );
            }
        }

        const itensAgrupados = {};
        for (const item of itens) {
            if (!itensAgrupados[item.item]) {
                itensAgrupados[item.item] = { ...item };
            } else {
                itensAgrupados[item.item].qtde += item.qtde;
            }
        }

        const itens_saldo_insuficiente = [];
        const itens_status_inativo = [];
        for (const item of Object.values(itensAgrupados)) {
            const { item: itemName, qtde } = item;

            const item_doc = await frappe.db.get_doc('Item', itemName);
            if (
                tipo === 'Saída' &&
                tipo_movimento !== 'Inventário' &&
                item_doc.manter_estoque
            ) {
                const last_movimento_doc = await frappe.call(
                    'nxlite.nx_estoque.doctype.movimento_de_estoque.movimento_de_estoque.get_last_movimento',
                    { empresa, item: itemName },
                );
                if (last_movimento_doc.message.saldo_posterior < qtde) {
                    itens_saldo_insuficiente.push(item_doc);
                }
            }

            if (item_doc.status !== 'Ativo') {
                itens_status_inativo.push(item_doc);
            }
        }

        if (itens_saldo_insuficiente.length) {
            return frappe.throw({
                title: 'Saldo insuficiente no(s) item(s)',
                message: itens_saldo_insuficiente
                    .map((i) => `Item: ${i.codigo} - ${i.descricao}`)
                    .join('<br>'),
            });
        }

        if (itens_status_inativo.length) {
            return frappe.throw({
                title: 'Item(s) com o status "Inativo"',
                message: itens_status_inativo
                    .map((i) => `Item: ${i.codigo} - ${i.descricao}`)
                    .join('<br>'),
            });
        }

        if (!doc_origem || typeof doc_origem !== 'string') {
            throw new Error(
                'O nome do Doctype de origem é obrigatório e deve ser uma string.',
            );
        }

        if (!id_doc_origem || typeof id_doc_origem !== 'string') {
            throw new Error(
                'O ID do documento de origem é obrigatório e deve ser uma string.',
            );
        }

        const docExists = await frappe.db.exists(doc_origem, id_doc_origem);
        if (!docExists) {
            throw new Error(
                `O documento de origem ${doc_origem} com ID ${id_doc_origem} não existe.`,
            );
        }

        if (tipo_movimento === 'Saída Automática' && movimento_gerador) {
            const movimento_exists = await frappe.db.exists(
                'Movimento de Estoque',
                movimento_gerador,
            );
            if (!movimento_exists) {
                throw new Error(
                    `O movimento de estoque gerador "${movimento_gerador}" não existe.`,
                );
            }
        }
    },

    get_last_movimento: async (empresa, item) => {
        if (!empresa && !item) {
            throw new Error(
                'Os dados de empresa e item são necessários para fazer a requisição.',
            );
        }

        const res = await frappe.call(
            'nxlite.nx_estoque.doctype.movimento_de_estoque.movimento_de_estoque.get_last_movimento',
            { empresa, item },
        );
        return res.message;
    },

    /**
     * Calcula os dados de conversão entre duas unidades de medida para um item específico.
     * 
     * @async
     * @function calculate_unit_conversion
     * 
     * @param {Object} params - Parâmetros para o cálculo da conversão.
     * @param {string} params.unidade_base - Unidade base para conversão.
     * @param {string} params.unidade_alternativa - Unidade alternativa para conversão.
     * @param {number} params.quantidade_un_base - Quantidade na unidade base.
     * @param {number} params.quantidade_un_alternativa - Quantidade na unidade alternativa.
     * @param {number} params.valor_un_base - Valor unitário na unidade base.
     * @param {number} params.valor_un_alternativa - Valor unitário na unidade alternativa.
     * @param {number} params.valor_total_un_base - Valor total na unidade base.
     * @param {number} params.valor_total_un_alternativa - Valor total na unidade alternativa.
     * @param {string} params.item_docname - Nome do documento do item.
     * @param {string} params.referencia - Campo de referência para o cálculo.
     * 
     * @returns {Object} Objeto contendo os valores calculados para unidade base e alternativa.
     * 
     * @throws {Error} Erro se unidade_base, unidade_alternativa ou item_docname forem inválidos ou se o campo referencia não for válido.
     */
    calculate_unit_conversion: async ({
        unidade_base = null,
        unidade_alternativa = null,
        quantidade_un_base = 0,
        quantidade_un_alternativa = 0,
        valor_un_base = 0,
        valor_un_alternativa = 0,
        valor_total_un_base = 0,
        valor_total_un_alternativa = 0,
        item_docname = null,
        referencia = 'unidade_base',
    }) => {
        // Valida parâmetros obrigatórios
        if (!unidade_base || !unidade_alternativa || !item_docname) {
            throw new Error('Um ou mais parâmetros obrigatórios ausentes: "unidade_base", "unidade_alternativa" ou "item_docname".');
        }

        // Define arrays de referência para base e alternativa
        const base_ref = ['unidade_base', 'quantidade_un_base', 'valor_un_base', 'valor_total_un_base'];
        const alternativa_ref = ['unidade_alternativa', 'quantidade_un_alternativa', 'valor_un_alternativa', 'valor_total_un_alternativa'];

        // Valida o parâmetro 'referencia'
        if (!base_ref.includes(referencia) && !alternativa_ref.includes(referencia)) {
            throw new Error('Parâmetro "referencia" inválido.');
        }

        // Define o grupo de referência (base ou alternativa) com base no valor de 'referencia'
        const ref_group = base_ref.includes(referencia) ? 'base' : 'alternativa';

        // Recupera dados da unidade alternativa do banco
        const rows_un_alternativa_item = (await frappe.call('nxlite.nxlite.utils.get_child_doc', {
            doctype: "itemunidade_medida",
            filters: [['parent', '=', item_docname], ['unidade_alternativa', '=', unidade_alternativa]],
            fields: ['fator_conversao', 'quantidade_na_unidade_alternativa', 'quantidade_na_unidade_de_estoque', 'status', 'unidade_alternativa', 'unidade_estoque'],
        })).message;

        // Verifica se há registros da unidade alternativa
        if (!rows_un_alternativa_item.length) {
            throw new Error('Nenhuma unidade alternativa correspondente encontrada para o item especificado.');
        }

        // Seleciona o primeiro registro da consulta e extrai o fator de conversão
        const row_found = rows_un_alternativa_item[0];
        const fator_conversao = row_found.fator_conversao;

        // Inicializa o objeto de resposta com valores básicos
        const res_obj = {
            unidade_base,
            unidade_alternativa,
            item_docname,
            referencia,
            fator_conversao,
        };

        // Realiza cálculos de conversão com base na referência do grupo (base ou alternativa)
        if (ref_group !== 'base') {
            res_obj['quantidade_un_alternativa'] = quantidade_un_alternativa;

            if (referencia === 'valor_total_un_alternativa') {
                res_obj['valor_total_un_alternativa'] = valor_total_un_alternativa;
                res_obj['valor_un_alternativa'] = valor_total_un_alternativa / quantidade_un_alternativa;
            } else {
                res_obj['valor_un_alternativa'] = valor_un_alternativa;
                res_obj['valor_total_un_alternativa'] = quantidade_un_alternativa * valor_un_alternativa;
            }

            res_obj['quantidade_un_base'] = res_obj.quantidade_un_alternativa / fator_conversao;
            res_obj['valor_un_base'] = res_obj.valor_total_un_alternativa / res_obj.quantidade_un_base;
            res_obj['valor_total_un_base'] = res_obj.quantidade_un_base * res_obj.valor_un_base;
        } else {
            res_obj['quantidade_un_base'] = quantidade_un_base;

            if (referencia === 'valor_total_un_base') {
                res_obj['valor_total_un_base'] = valor_total_un_base;
                res_obj['valor_un_base'] = valor_total_un_base / quantidade_un_base;
            } else {
                res_obj['valor_un_base'] = valor_un_base;
                res_obj['valor_total_un_base'] = quantidade_un_base * valor_un_base;
            }

            res_obj['quantidade_un_alternativa'] = res_obj.quantidade_un_base / fator_conversao;
            res_obj['valor_un_alternativa'] = res_obj.valor_total_un_base / res_obj.quantidade_un_alternativa;
            res_obj['valor_total_un_alternativa'] = res_obj.quantidade_un_alternativa * res_obj.valor_un_alternativa;
        }

        // Retorna o objeto de resposta com os valores calculados
        return res_obj;
    },

    /**
     * Calcula e atualiza campos de conversão de unidades em uma linha de item.
     *
     * Essa função utiliza os valores da linha fornecida para calcular dados de conversão de unidade e,
     * com base nos resultados, atualiza os campos correspondentes no formulário.
     *
     * @async
     * @param {Object} options - Parâmetros da função.
     * @param {Object} options.row - Linha de item que contém os valores de entrada para conversão.
     * @param {string} [options.trigger='item'] - Campo que serve como referência para o cálculo de conversão.
     * @param {Object} options.ref_conversor - Objeto de mapeamento entre parâmetros e nomes de campo.
     * @returns {Promise<Object>} Retorna um objeto com os valores calculados da conversão.
     */
    calculate_and_update_fields_unit_conversion: async ({ row, trigger = 'item', ref_conversor }) => {
       
        // Mapeia os valores da linha para os parâmetros necessários para a função de conversão
        const params = {
            item_docname: row[ref_conversor?.param_to_fieldname['item_docname']],
            unidade_base: row[ref_conversor?.param_to_fieldname['unidade_base']],
            unidade_alternativa: row[ref_conversor?.param_to_fieldname['unidade_alternativa']],
            quantidade_un_base: row[ref_conversor?.param_to_fieldname['quantidade_un_base']],
            quantidade_un_alternativa: row[ref_conversor?.param_to_fieldname['quantidade_un_alternativa']],
            valor_un_base: row[ref_conversor?.param_to_fieldname['valor_un_base']],
            valor_un_alternativa: row[ref_conversor?.param_to_fieldname['valor_un_alternativa']],
            valor_total_un_base: row[ref_conversor?.param_to_fieldname['valor_total_un_base']],
            valor_total_un_alternativa: row[ref_conversor?.param_to_fieldname['valor_total_un_alternativa']],
            referencia: ref_conversor?.fieldname_to_param[trigger],
        }

        // Chama a função de conversão de unidade e obtém os valores calculados
        const values = await estoque_utils.calculate_unit_conversion(params)

        // Identifica o doctype e o nome do documento para atualizar
        const cdt = row.doctype;
        const cdn = row.name;

        // Itera sobre os valores retornados e atualiza os campos na linha do item, se necessário
        for (const key in values) {

            // Verifica se a chave está presente no mapeamento de parâmetros para nomes de campo
            if (!Object.keys(ref_conversor.param_to_fieldname).includes(key)) continue;

            // Obtém o nome do campo e o valor anterior para comparar
            const fieldname = ref_conversor.param_to_fieldname[key]
            const prev_value = row[fieldname]

            // Atualiza o valor somente se houver uma diferença
            if (prev_value !== values[key]) {
                locals[cdt][cdn][fieldname] = values[key]
            }
        }

        // Atualiza o formulário para refletir as mudanças nos itens
        cur_frm.refresh_field('itens')

        return values
    },
};

frappe.nxlite.utils.estoque = estoque_utils;
